* {
	font-family: 'Fredoka', sans-serif !important;
	font-style: normal;
	font-weight: 600;
	color: #2e3192;
	text-decoration: none !important;
}
.popup-box {
	position: absolute;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
}
.question-link {
	text-decoration: underline !important;
}
.box {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 90%;
	height: 95%;
	min-height: 300px;
	background: #fffcfa;
	box-shadow: inset 0px -3px 23px rgba(44, 54, 143, 0.71);
	border-radius: 21px;
	margin: 10px auto;
	padding: 20px;
}
.box-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
}
.box-title {
	font-style: normal;
	font-size: 22px;
	line-height: 36px;
	text-align: center;
	letter-spacing: -0.01em;
}

.box-text {
	font-style: normal;
	font-size: 16px;
	line-height: 25px;
	text-align: center;
	letter-spacing: -0.01em;
}
.start-btn {
	background: #2e3192;
	border-radius: 57px;
	padding: 4px 50px;
}
.box-btn {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.box-btn-next {
	margin: 0 30px 0 30px;
	background: #2e3192;
	border-radius: 6.5px;
	padding: 0 20px;
}

.box-btn-text-next {
	font-style: normal;
	font-size: 16px;
	line-height: 38px;

	text-align: right;
	letter-spacing: -0.01em;
	text-decoration: none !important;
	color: #ffffff;
}

.box-btn-end {
	margin: 10px 30px 0 30px;
	background: #ffffff;
	border: 1px solid #2e3192;
	border-radius: 6.5px;
}

.box-btn-text-end {
	font-size: 16px;
	line-height: 38px;
}
.res-title {
	font-size: 26px;
	line-height: 31px;
	font-weight: bold;
}

.res-score {
	font-size: 80px;
	line-height: 97px;
}
.res-text {
	font-size: 20px;
	line-height: 28px;
	letter-spacing: -0.01em;
	font-weight: bold;
	color: #2e3192;
}
.res-share {
	font-size: 15px;
}
